import React from 'react';
import styled from 'styled-components';
import { Meta } from '../components/common/Meta';
import { color, font, mediaQuery } from '../styles/variables';
import { useMediaQuery } from 'react-responsive';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 4rem 0;

  @media screen and ${mediaQuery.tablet} {
    padding: 2rem 2rem;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min(100%, 800px);
  gap: 1rem;
  @media screen and ${mediaQuery.tablet} {
    line-height: 1.5;
    margin-bottom: 1.5rem;
  }
`;

const MainHeading = styled.h3`
  width: 100%;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 100;
  letter-spacing: 0.1em;
  color: ${color.black};
`;

const SubHeading = styled.div`
  width: 100%;
  font-size: 1rem;
  color: ${color.black};
  font-family: ${font.sansSerif};
  line-height: 1.5rem;
`;

const WarningText = styled.p`
  font-size: 0.7rem;
  line-height: 1.7;
  color: ${color.grey[800]};
  div {
    padding: 8px 0;
  }
`;

const ContactPage = () => {
  const isMobile = useMediaQuery({
    query: mediaQuery.mobile,
  });

  return (
    <PageWrapper>
      <Meta title={'お問い合わせ'} />
      <ContentWrapper>
        <MainHeading>メールフォームでの{isMobile && <br />}お問い合わせ</MainHeading>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <SubHeading>
            ・クリニックでの美容施術についてのお問い合わせは、
            <a href="https://www.clinicfor.life/contact/">CLINICFORのお問い合わせページ</a>
            から、ご希望のクリニックをご選択の上でお問い合わせください。
          </SubHeading>
          <SubHeading>
            ・ご予約をお持ちの方で、予約の変更・キャンセルをご希望の方は、ご予約日前日の18時までに専用ダイヤル「050-3816-4682」までご連絡いただけますようお願いいたします。(営業時間：平日
            9：00～18：00、土日祝 9：00～18：00)
            <WarningText>
              <div>
                ※ コース・サブスクプランご契約中の場合、無断キャンセルされますと１回分消化扱いとなりますのでご注意ください。
                <br />
                ※こちらは、クリニック対面美容皮膚科で予約をお持ちの方の変更/キャンセル専用ダイヤルです。
                <br />
                ※オンライン診療に関する問い合わせには対応できませんのでご了承ください。
              </div>
            </WarningText>
          </SubHeading>
        </div>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default ContactPage;
