import React from 'react';
import styled from 'styled-components';
import { color, mediaQuery } from '../../../styles/variables';
import { MenuTreatmentProps } from './TreatmentList';

type Props = Pick<MenuTreatmentProps, 'contentId' | 'name' | 'description' | 'trouble'>;

const TreatmentBody: React.VFC<Props> = ({ contentId, name, description, trouble }) => {
  return (
    <Container>
      <Heading>{name}</Heading>
      <Text>
        {description.map((text, i) => (
          <p key={`TreatmentDescription-${contentId}-${i}`}>{text}</p>
        ))}
      </Text>
      {trouble && (
        <Trouble>
          <p>お悩み</p>
          <TroubleList>
            <ul>
              {trouble.map((item, i) => (
                <li key={`Trouble-${contentId}-${i}`}>{item}</li>
              ))}
            </ul>
          </TroubleList>
        </Trouble>
      )}
    </Container>
  );
};

const Container = styled.div`
  color: ${color.grey[900]};
  margin-top: 3rem;
`;

const Heading = styled.div`
  text-align: center;
  font-size: 3rem;
  font-weight: 700;

  @media screen and ${mediaQuery.tablet} {
    font-size: 1.25rem;
  }
`;

const Text = styled.div`
  padding: 0 1rem;
  font-size: 1rem;
  margin-top: 1rem;
  text-align: center;

  @media screen and ${mediaQuery.tablet} {
    font-size: 0.8rem;
  }
`;

const Trouble = styled.div`
  display: flex;
  margin-top: 2rem;
  align-items: center;
  border: 1px solid ${color.black};

  > p {
    font-size: 1rem;
    border-right: 1px solid ${color.black};
    padding: 1rem;
    text-align: center;
    flex: 0 0 8rem;
  }

  @media screen and ${mediaQuery.tablet} {
    display: block;

    > p {
      border-right: none;
      border-bottom: 1px solid ${color.black};
      padding: 0.2rem 0;
      font-size: 0.9rem;
    }
  }
`;

const TroubleList = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  font-size: 0.9rem;

  > ul {
    list-style: none;
    margin: 0;
    padding: 1rem 1rem 1rem 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > li {
      position: relative;
      margin-left: 2rem;
      line-height: 2;

      &::before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        background-color: ${color.pink};
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: -1rem;
      }
    }
  }

  @media screen and ${mediaQuery.tablet} {
    font-size: 0.75rem;
  }
`;

export default TreatmentBody;
