import React from 'react';
import styled from 'styled-components';

import { PriceProps } from '../../pages/price';
import { color } from '../../styles/variables';

type Props = PriceProps['content'][number]['available'];

const AvailableClinic: React.VFC<Props> = ({ yotsuya, tamachi, shimbashi, shinsaibashi, shibuya, ikebukuro }) => (
  <Container>
    <ClinicStatus available={shimbashi}>新橋</ClinicStatus>
    <ClinicStatus available={tamachi}>田町</ClinicStatus>
    <ClinicStatus available={ikebukuro}>池袋</ClinicStatus>
    <ClinicStatus available={yotsuya}>四谷</ClinicStatus>
    <ClinicStatus available={shinsaibashi}>心斎橋</ClinicStatus>
  </Container>
);

const Container = styled.ul`
  margin: 0 auto;
  width: 4rem;
  padding: 0;
  list-style: none;

  > li + li {
    margin-top: 0.1rem;
  }
`;

const ClinicStatus = styled.li<{ available: boolean }>`
  padding: 0;
  font-size: 0.7rem;
  border: 1px solid ${color.black};
  background-color: ${(props) => (props.available ? color.pink : color.white)};
`;

export default AvailableClinic;
