import React, { useCallback, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import Slider from './Slider';
import { color, mediaQuery } from '../../styles/variables';
import { motion, useAnimation } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import { HeroCopy3LineSVG } from '../common/TextSVG';
import { ReservationLinks } from '../common/ReservationLinks';

// slideのコンテナ自体のアニメーション設定
const slideVariants = {
  enter: { y: 120, opacity: 0 },
  center: { y: 0, opacity: 1 },
};

// decorationのアニメーション設定
const decorationVariants = {
  enter: { width: '0' },
  center: { width: '100%' },
};

const TopHero: React.VFC = () => {
  const isMobile = useMediaQuery({
    query: mediaQuery.mobile,
  });
  const slideControls = useAnimation();
  const decorationControls = useAnimation();

  // slideの画像をloadして、slideのコンテナがアニメーションを開始させる
  // それが完了したらdecorationのアニメーションを開始
  const animateHero = useCallback(async () => {
    await slideControls.start('center');
    return decorationControls.start('center');
  }, [decorationControls, slideControls]);

  useEffect(() => {
    animateHero();
  }, [animateHero]);

  return (
    <Container>
      <Hero>
        <Heading>
          <h1>かかりつけの、美容皮膚科ができること。</h1>
          <HeroCopy3LineSVG />
          <DecorationContainer>
            <Decoration
              initial={'enter'}
              animate={decorationControls}
              variants={decorationVariants}
              transition={{
                width: { duration: 1.2, ease: [0.76, 0, 0.24, 1] },
              }}
            />
          </DecorationContainer>
        </Heading>
        <HeroImage
          initial={'enter'}
          animate={slideControls}
          variants={slideVariants}
          transition={{
            opacity: { duration: 1, ease: 'easeOut' },
            y: { duration: 1.2, ease: [0.19, 0.97, 0.45, 0.99] },
          }}
        >
          <Slider />
          {!isMobile && <p>キレイな肌、健康なカラダ、美しい毎日。</p>}
        </HeroImage>
      </Hero>
      {isMobile && <SubCopySP>キレイな肌、健康なカラダ、美しい毎日。</SubCopySP>}
      <SubscriptionLink>
        <a href="https://www.clinicfor.life/beauty/lp/subscription/">
          <StaticImage src="../../images/top/subscription_lp_link.png" alt="" />
        </a>
      </SubscriptionLink>
      <ReservationLinks />
      <OnlineShopLink />
    </Container>
  );
};

const OnlineShopLinkStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .shop-button-wrapper {
    display: flex;
    flex-direction: column;
    gap: 11px;
    width: 20rem;
    align-items: center;
    .shop-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 1px solid #cfcfcf;
      border-radius: 100px;
      height: 45px;
      width: 100%;
      gap: 11px;
      padding: 0 12px;
      text-decoration: none;
      img {
        width: 16px;
      }
      .text {
        font-size: 14px;
        font-weight: 500;
        color: black;
      }
    }
    .text {
      font-size: 12px;
      font-weight: 300;
    }
    .shop-button:hover {
      background: #f4f4f4;
    }
  }
`;

const OnlineShopLink = () => (
  <OnlineShopLinkStyle>
    <div className="shop-button-wrapper">
      <a className="shop-button" href="https://shop.clinicfor.life/">
        <StaticImage src="../../images/top/shop-bag.png" alt="" />
        <span className="text">オンラインショップはこちら</span>
      </a>
      <span className="text">※無料オンラインカウンセリングが可能です。</span>
    </div>
  </OnlineShopLinkStyle>
);

const Container = styled.div`
  padding: 0 0 6rem;

  @media screen and ${mediaQuery.mobile} {
    padding: 0rem 0 3rem;
  }
`;

const Hero = styled.div`
  position: relative;
  width: 100%;
  max-width: 78rem;
  padding: 5rem 0;
  margin: 0 auto;

  @media screen and ${mediaQuery.mobile} {
    height: 16rem;
  }
`;

const Heading = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  padding-left: 2rem;

  > h1 {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  > svg {
    width: 30%;
    z-index: 3;
  }

  @media screen and ${mediaQuery.mobile} {
    > svg {
      width: 42%;
    }
  }
`;

const SubCopySP = styled.div`
  text-align: center;
  margin: 1rem 0 0;
  font-size: 0.8rem;
`;

const DecorationContainer = styled(motion.div)`
  width: 100%;
  height: 80%;
  position: absolute;
  right: 60%;
  bottom: -46%;
  z-index: 2;
  opacity: 0.5;

  @media screen and ${mediaQuery.mobile} {
    right: 40%;
    height: 130%;
    bottom: -100%;
  }
`;

const Decoration = styled(motion.div)`
  background-color: ${color.pink};
  width: 100%;
  height: 100%;
`;

const HeroImage = styled(motion.div)`
  position: absolute;
  width: 63%;
  height: 100%;
  right: 0;
  top: 0;

  > p {
    font-size: 1rem;
    position: relative;
    font-feature-settings: 'palt' 1;
    letter-spacing: 0.15em;
    margin: 0.5rem 0 0;
    z-index: 3;
  }

  @media screen and ${mediaQuery.mobile} {
    width: 48%;
  }
`;

const SubscriptionLink = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 6rem;

  @media screen and ${mediaQuery.laptop} {
    margin-top: 4rem;
  }

  > a {
    width: 440px;
    height: 120px;
    margin-left: 30px;
    margin-right: 30px;
  }
`;

export default TopHero;
