import React from 'react';
import styled from 'styled-components';
import { color, mediaQuery } from '../../../styles/variables';
import { useMediaQuery } from 'react-responsive';
import { MenuTreatmentProps } from './TreatmentList';
import { StaticImage } from 'gatsby-plugin-image';

type Props = Pick<MenuTreatmentProps, 'contentId' | 'feature' | 'time' | 'time2' | 'effect'>;

const TreatmentDetail: React.VFC<Props> = ({ feature, contentId, time, time2, effect }) => {
  const isLaptop = useMediaQuery({
    query: mediaQuery.laptop,
  });
  return (
    <Container>
      {feature && (
        <FeatureContainer>
          <ContentHead>特長</ContentHead>
          <FeatureList>
            {feature.map((feature, i) => (
              <li key={`Feature-${contentId}-${i}`}>{feature}</li>
            ))}
          </FeatureList>
          {time && (
            <>
              <TimeContainer>
                {time.iconType && (
                  <TimeHead>
                    <TimeIconContainer>
                      {time.iconType === 15 && <StaticImage src={'../../../images/menu/time15.png'} alt={''} />}
                      {time.iconType === 30 && <StaticImage src={'../../../images/menu/time30.png'} alt={''} />}
                      {time.iconType === 45 && <StaticImage src={'../../../images/menu/time45.png'} alt={''} />}
                      {time.iconType === 60 && <StaticImage src={'../../../images/menu/time60.png'} alt={''} />}
                    </TimeIconContainer>
                    <p>
                      施術
                      {!isLaptop && <br />}
                      時間目安
                    </p>
                  </TimeHead>
                )}
                <TimeContent>
                  <Time>{time.main}</Time>
                  {time.option && <small>{time.option}</small>}
                  {time.condition && <Condition>{time.condition}</Condition>}
                </TimeContent>
              </TimeContainer>
              {/* ウルトラセルQが２個表記する必要があったため、２個目がある場合を暫定的に対応*/}
              {time2 && (
                <TimeContainer>
                  {time2.iconType && (
                    <TimeHead>
                      <TimeIconContainer>
                        {time2.iconType === 15 && <StaticImage src={'../../../images/menu/time15.png'} alt={''} />}
                        {time2.iconType === 30 && <StaticImage src={'../../../images/menu/time30.png'} alt={''} />}
                        {time2.iconType === 45 && <StaticImage src={'../../../images/menu/time45.png'} alt={''} />}
                        {time2.iconType === 60 && <StaticImage src={'../../../images/menu/time60.png'} alt={''} />}
                      </TimeIconContainer>
                      <p>
                        施術
                        {!isLaptop && <br />}
                        時間目安
                      </p>
                    </TimeHead>
                  )}
                  <TimeContent>
                    <Time>{time2.main}</Time>
                  </TimeContent>
                </TimeContainer>
              )}
              {time.annotation && <Annotation>{time.annotation}</Annotation>}
            </>
          )}
        </FeatureContainer>
      )}
      {effect && (
        <EffectContainer>
          <ContentHead>副作用・治療間隔</ContentHead>
          {effect.content && (
            <>
              <Effect>
                <EffectHead>【副作用】</EffectHead>
                {effect.content.map((content, i) => (
                  <EffectContent key={`EffectContentText-${contentId}-${i}`}>
                    <div>
                      {content.head && <EffectContentHead>{content.head}</EffectContentHead>}
                      {content.text}
                    </div>
                  </EffectContent>
                ))}
              </Effect>
              {effect.interval && (
                <Effect>
                  <EffectHead>【治療期間】</EffectHead>
                  <EffectContent>{effect.interval}</EffectContent>
                </Effect>
              )}
            </>
          )}
          {effect.centeredText && <EffectCenteredText>{effect.centeredText}</EffectCenteredText>}
        </EffectContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 60rem;
  margin: 2rem auto 0;
  color: ${color.grey[900]};

  @media screen and ${mediaQuery.laptop} {
    display: block;
  }
`;

const FeatureContainer = styled.div`
  background-color: ${color.grey[100]};
  flex: 1;
  padding: 1rem;
`;
const ContentHead = styled.div`
  font-size: 1.2rem;
  border-bottom: 1px solid ${color.black};
  width: 100%;
  letter-spacing: 0.2em;
  padding-bottom: 0.5rem;
  text-align: center;
`;
const FeatureList = styled.ul`
  list-style: none;
  padding: 0 0 0 1.5rem;
  margin: 1rem 0 0;

  > li {
    font-size: 0.9rem;
    position: relative;
    line-height: 2;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0.5rem;
      left: -1.2rem;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: ${color.pink};
    }
  }

  @media screen and ${mediaQuery.tablet} {
    > li {
      font-size: 0.8rem;
    }
  }
`;
const TimeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0 0;

  @media screen and ${mediaQuery.laptop} {
    display: block;
  }
`;

const TimeHead = styled.div`
  background-color: ${color.white};
  display: flex;
  align-items: center;
  border-right: 4px solid ${color.grey[200]};
  padding: 0.5rem;

  > p {
    margin-left: 0.5rem;
  }

  @media screen and ${mediaQuery.laptop} {
    padding: 0.2rem;
    font-size: 0.8rem;
    border-right: none;
    border-bottom: 4px solid ${color.grey[200]};
  }
`;

const TimeIconContainer = styled.div`
  width: 60px;

  @media screen and ${mediaQuery.laptop} {
    width: 32px;
  }
`;

const TimeContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: ${color.white};
  padding: 1rem 0;

  > small {
    font-size: 0.75rem;
    margin-left: 0.5rem;
  }

  @media screen and ${mediaQuery.laptop} {
    padding: 0.5rem;

    > small {
      font-size: 0.7rem;
    }
  }
`;

const Time = styled.p`
  font-size: 1.4rem;
  @media screen and ${mediaQuery.laptop} {
    font-size: 1rem;
  }
`;

const Condition = styled.div`
  border: 1px solid ${color.black};
  font-size: 0.9rem;
  padding: 0.2rem 0.5rem;
  margin-left: 1rem;

  @media screen and ${mediaQuery.laptop} {
    font-size: 0.75rem;
  }
`;

const Annotation = styled.p`
  font-size: 0.75rem;
  margin-top: 0.5rem;

  @media screen and ${mediaQuery.laptop} {
    font-size: 0.75rem;
  }
`;

const EffectContainer = styled.div`
  background-color: ${color.grey[200]};
  flex: 0 0 35%;
  padding: 1rem;
`;

const Effect = styled.div`
  margin-top: 1rem;
`;
const EffectHead = styled.div`
  font-size: 0.8rem;
`;
const EffectContent = styled.div`
  font-size: 0.9rem;
  margin: 0.2rem 0 0;

  @media screen and ${mediaQuery.laptop} {
    font-size: 0.8rem;
  }
`;

const EffectContentHead = styled.div`
  background-color: ${color.black};
  color: ${color.white};
  display: inline-block;
  padding: 0.1rem 0.5rem;
  font-size: 0.75rem;
  margin: 0 0.5rem 0 0;
`;

const EffectCenteredText = styled.div`
  text-align: center;
  margin: 4rem 0 0;

  @media screen and ${mediaQuery.laptop} {
    margin: 1rem 0 0;
  }
`;

export default TreatmentDetail;
