import React from 'react';
import styled from 'styled-components';
import TroubleListItem from './TroubleListItem';
import { graphql, useStaticQuery } from 'gatsby';

export type TroubleProps = {
  contentId: number;
  title: string;
  description: string;
  recommendation: string[];
  clinic: string[];
  home: string[];
};

const TroubleList = () => {
  const TroubleData = useStaticQuery(graphql`
    query TroubleData {
      allTroubleContentYaml(sort: { order: ASC, fields: contentId }) {
        nodes {
          contentId
          description
          clinic
          home
          recommendation
          title
        }
      }
    }
  `);
  const data = TroubleData.allTroubleContentYaml.nodes;
  return (
    <Container>
      {data.map((content: TroubleProps) => (
        <TroubleListItem {...content} key={`TroubleContent-${content.contentId}`} />
      ))}
    </Container>
  );
};

const Container = styled.div``;

export default TroubleList;
