import React from 'react';
import styled from 'styled-components';
import { ClinicIcon, HomeIcon } from '../../common/Icon';
import { color, mediaQuery } from '../../../styles/variables';
import { TroubleProps } from './TroubleList';
import { troubleContentList } from '../../../utils/troubleContentList';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const TroubleListItem: React.VFC<TroubleProps> = ({ contentId, title, description, recommendation, home, clinic }) => {
  const troubleContent = (id: string) => troubleContentList.find((content) => content.name === id);
  return (
    <Container id={`trouble-${contentId}`}>
      <Title>{title}</Title>
      <FlexLayout>
        <Description>{description}</Description>
        <Recommendation>
          <p>こんな方におすすめ</p>
          <ul>
            {recommendation.map((item, i) => (
              <li key={`Trouble-Recommendation-${contentId}-${i}`}>{item}</li>
            ))}
          </ul>
        </Recommendation>
      </FlexLayout>
      <ListContainer>
        <ListContainerHead>
          <ClinicIcon size={32} />
          <p>クリニックでの施術</p>
        </ListContainerHead>
        <List>
          {clinic.map((item, i) => (
            <li key={`TroubleLink-Clinic-${contentId}-${i}`}>
              {troubleContent(item)?.externalLink && <a href={troubleContent(item)?.externalLink}>{troubleContent(item)?.text}</a>}
              {troubleContent(item)?.link && <AnchorLink to={troubleContent(item)?.link ?? ''}>{troubleContent(item)?.text}</AnchorLink>}
            </li>
          ))}
        </List>
      </ListContainer>
      {home && (
        <ListContainer>
          <ListContainerHead>
            <HomeIcon size={32} />
            <p>ご自宅での治療</p>
          </ListContainerHead>
          <List>
            {home.map((item, i) => (
              <li key={`TroubleLink-Home-${contentId}-${i}`}>
                {troubleContent(item)?.externalLink && <a href={troubleContent(item)?.externalLink}>{troubleContent(item)?.text}</a>}
                {troubleContent(item)?.link && <AnchorLink to={troubleContent(item)?.link ?? ''}>{troubleContent(item)?.text}</AnchorLink>}
              </li>
            ))}
          </List>
        </ListContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin: 6rem auto 0;
  max-width: 60rem;
  padding: 0 1rem;
  width: 100%;
`;
const Title = styled.div`
  background-color: ${color.pink};
  padding: 1rem;
  text-align: center;
  font-size: 1rem;
`;

const FlexLayout = styled.div`
  margin: 2rem 0 0;
  display: flex;
  justify-content: space-between;

  @media screen and ${mediaQuery.laptop} {
    display: block;
  }
`;

const Description = styled.div`
  width: 48%;
  font-size: 0.9rem;
  line-height: 1.8;
  @media screen and ${mediaQuery.laptop} {
    width: 100%;
  }
`;

const Recommendation = styled.div`
  width: 48%;
  border: 1px solid ${color.black};
  padding: 1rem;

  > p {
    border-bottom: 1px solid ${color.black};
    text-align: center;
    padding: 0.5rem;
  }

  > ul {
    list-style: none;
    padding: 0 0 0 2rem;
    margin: 1rem 0 0;
    font-size: 0.9rem;

    > li {
      position: relative;
      line-height: 1.8;

      &::before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        background-color: ${color.pink};
        position: absolute;
        top: 0.6rem;
        left: -1.2rem;
      }
    }
  }

  @media screen and ${mediaQuery.laptop} {
    width: 100%;
    margin: 2rem 0 0;
  }
`;

const ListContainer = styled.div`
  margin: 2rem 0 0;
`;

const ListContainerHead = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > p {
    margin-left: 1rem;
  }
`;

const List = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.5rem;
  padding: 0;
  margin: 0.5rem 0 0;
  font-size: 1rem;

  > li {
    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      height: 100%;
      background-color: ${color.grey[100]};
      padding: 1rem 0.5rem;
      color: ${color.grey[900]};

      &:hover {
        background-color: ${color.pink};
      }
    }
  }

  @media screen and ${mediaQuery.laptop} {
    grid-template-columns: 1fr 1fr;
    font-size: 0.8rem;
  }
`;

export default TroubleListItem;
